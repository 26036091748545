import { useMemo } from 'react';

import Link from 'next/link';

import { useAudioPlayer } from '@/components/ListenPage/AudioProvider';
import { Container } from '@/components/ListenPage/Container';
import { FormattedDate } from '@/components/ListenPage/FormattedDate';

import { PlayIcon, StopIcon } from '@heroicons/react/outline';
import prepareAudioFile from '../../utils/PrepareAudioFile';

export default function EpisodeEntry({ episode, vault, onClick }) {
  let date = new Date(episode.created_at);

  let audioPlayerData = useMemo(
    () => (prepareAudioFile(episode)),
    [episode]
  );
  let player = useAudioPlayer(audioPlayerData);

  return (
    <article
      aria-labelledby={`episode-${episode.id}-title`}
      className={`${!vault ? 'py-8 sm:py-10' : 'py-0'}`}
    >
      <Container>
        <div className="flex flex-col items-start">
          <h2 id={`episode-${episode.id}-title`} className="mt-2 text-lg font-bold text-gray-900">
            <Link href={`/l/${episode.uid}`}>{episode.title}</Link>
          </h2>
          <FormattedDate
            date={date}
            className="order-first font-mono text-sm leading-7 text-gray-500"
          />
          <p className="mt-1 text-base leading-7 text-gray-700">{episode.description}</p>
          <div className="mt-4 flex items-center gap-4">
            <button
              type="button"
              onClick={() => {
                player.toggle();
                onClick && onClick(episode);
              }}
              className="flex items-center text-sm font-bold leading-6 text-teal-600 hover:text-teal-700 active:text-teal-900"
              aria-label={`${player.playing ? 'Pause' : 'Play'} episode ${episode.title}`}
            >
              {!player.playing ? (
                <PlayIcon className="h-5 w-5" />
              ) : (
                <StopIcon className="h-5 w-5" />
              )}
              <span className="ml-3" aria-hidden="true">
                Posłuchaj
              </span>
            </button>
            {!vault ? (
              <>
                <span aria-hidden="true" className="text-sm font-bold text-gray-400">
                  /
                </span>
                <Link
                  href={`/l/${episode.uid}`}
                  className="flex items-center text-sm font-bold leading-6 text-teal-600 hover:text-teal-700 active:text-teal-900"
                  aria-label={`Show notes for episode ${episode.title}`}
                >
                  Pokaż notatki
                </Link>
              </>
            ) : null}
          </div>
        </div>
      </Container>
    </article>
  );
}
