import Image from 'next/image';
import Link from 'next/link';
import BookISBNDetails from '../BookISBNDetails';

export default function ShowLoadedBookIsbnInfo({ bookisbn }) {
  return (
    <div className="relative z-10 mx-auto max-h-max px-4 pb-4 pt-10 sm:px-6 md:max-w-2xl md:px-4 lg:flex-auto lg:border-x lg:border-slate-200 lg:py-12 lg:px-8 xl:px-12">
      <Image
        className="w-full rounded-lg"
        src={
          bookisbn.thumbnailMedium
            ? bookisbn.thumbnailMedium
            : require('@/assets/images/book_cover_unavailable.png')
        }
        alt="Cover"
        width={200}
        height={300}
        priority
        fill={false}
      />
      <Link
        href={`/book/${bookisbn.slug}`}
        className="hover:text-blue-500 hover:underline"
        aria-label="Homepage"
        shallow
      >
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {bookisbn.title}
        </h2>
      </Link>
      <BookISBNDetails bookisbn={bookisbn}></BookISBNDetails>
    </div>
  );
}
