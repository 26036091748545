import { LanguageIcon } from '@/components/common/Icons';
import TextExpander from '@/components/common/TextExpander';
import {
  BookOpenIcon,
  BookmarkAltIcon,
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
  ColorSwatchIcon,
  HashtagIcon,
  LibraryIcon,
  MapIcon,
  OfficeBuildingIcon,
  SparklesIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';

const BOOK_DETAILS = [
  {
    srInfo: 'Dziesięciocyfrowy kod ISBN',
    info: 'ISBN 10',
    parameterName: 'ISBN10',
    icon: HashtagIcon,
  },
  {
    srInfo: 'Trzynastocyfrowy kod ISBN',
    info: 'ISBN 13',
    parameterName: 'ISBN13',
    icon: HashtagIcon,
  },
  {
    srInfo: 'Trzynastocyfrowy kod EAN',
    info: 'EAN 13',
    parameterName: 'EAN13',
    icon: HashtagIcon,
  },
  {
    srInfo: '9 cyfrowy numer identyfikacyjny książek wydanych przed ISBN',
    info: 'Nasz Nr',
    parameterName: 'REF',
    icon: HashtagIcon,
  },
  {
    srInfo: 'Grupa Demograficzna',
    info: 'Grupa odbiorców',
    parameterName: 'demographicGroup',
    icon: UserGroupIcon,
  },
  {
    srInfo: 'Liczba stron',
    info: 'Liczba stron',
    parameterName: 'pageCount',
    icon: BookOpenIcon,
  },
  {
    srInfo: 'Kategorie',
    info: 'Kategorie',
    parameterName: 'categories',
    icon: BookmarkAltIcon,
  },
  {
    srInfo: 'Język książki',
    info: 'Język',
    parameterName: 'language',
    icon: LanguageIcon,
  },
  {
    srInfo: 'Język oryginału książki',
    info: 'Język oryginału',
    parameterName: 'languageOfOriginal',
    icon: LanguageIcon,
  },
  {
    srInfo: 'Data wydania',
    info: 'Data wydania',
    parameterName: 'publishedDate',
    icon: CalendarIcon,
  },
  {
    srInfo: 'Rok publikacji',
    info: 'Rok publikacji',
    parameterName: 'publicationYear',
    icon: CalendarIcon,
  },
  {
    srInfo: 'Miejsce wydania',
    info: 'Miejsce wydania',
    parameterName: 'placeOfPublication',
    icon: MapIcon,
  },
  {
    srInfo: 'Wydawca',
    info: 'Wydawca',
    parameterName: 'publisher',
    icon: LibraryIcon,
  },
  {
    srInfo: 'Grupa docelowych odbiorców',
    info: 'Odbiorcy',
    parameterName: 'audienceGroup',
    icon: UserGroupIcon,
  },
  {
    srInfo: 'Tematyka',
    info: 'Tematyka',
    parameterName: 'subject',
    icon: SparklesIcon,
  },
  {
    srInfo: 'Okres stworzenia',
    info: 'Okres stworzenia',
    parameterName: 'timePeriodOfCreation',
    icon: ClockIcon,
  },
  {
    srInfo: 'Temat książki',
    info: 'Temat książki',
    parameterName: 'subjectWork',
    icon: ClockIcon,
  },
  {
    srInfo: 'Umiejscowienie pracy',
    info: 'Umiejscowienie pracy',
    parameterName: 'subjectPlace',
    icon: MapIcon,
  },
  {
    srInfo: 'Umiejscowienie czasu historii',
    info: 'Czas historii',
    parameterName: 'subjectTime',
    icon: ClockIcon,
  },
  {
    srInfo: 'Domena',
    info: 'Domena',
    parameterName: 'domain',
    icon: OfficeBuildingIcon,
  },
  {
    srInfo: 'Rodzaj publikacji',
    info: 'Rodzaj publikacji',
    parameterName: 'formOfWork',
    icon: ColorSwatchIcon,
  },
  {
    srInfo: 'Gatunek publikacji',
    info: 'Gatunek publikacji',
    parameterName: 'genre',
    icon: BriefcaseIcon,
  },
];

export default function BookISBNDetails({ bookisbn }) {
  return (
    <>
      <div className="border-gray-200 pt-10">
        <h3 className="text-sm font-medium text-gray-900">Opis książki</h3>
        <TextExpander className="mt-6 text-gray-500" text={bookisbn.description}></TextExpander>
      </div>
      <div className="mt-3 border-t border-gray-200 pt-10">
        <h3 className="text-sm font-medium text-gray-900">Informacje o książce</h3>
        <dl className="flex flex-wrap">
          {BOOK_DETAILS.map((item) => {
            return (
              bookisbn[item.parameterName] && (
                <div className="flex w-full flex-none gap-x-4 border-gray-900/5 px-6 pt-3">
                  <dt className="flex-none">
                    <span className="sr-only">{item.srInfo}</span>
                    <item.icon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-6">{item.info}</dd>
                  <dd className="ml-auto rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {bookisbn[item.parameterName]}
                  </dd>
                </div>
              )
            );
          })}
        </dl>
      </div>
    </>
  );
}
