import { SEOHeader } from '@/components/Headers';
import { Container } from '@/components/ListenPage/Container';
import EpisodeEntry from '@/components/common/EpisodeEntry';
import ShowDefaultImageToggler from '@/components/common/ShowDefaultImageToggler';
import ShowLoadedBookIsbnInfo from '@/components/common/ShowLoadedBookIsbnInfo';
import { useState } from 'react';

export default function Home({ episodes }) {
  const [bookisbn, setBookisbn] = useState(null);

  const handleReviewPlay = (episode) => {
    fetch(`${process.env.NEXT_PUBLIC_DJANGO_URL}/api/bookle/isbn/?id=${episode.bookisbn.id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBookisbn(data.results[0]);
      });
  };

  return (
    <>
      <div className="bg-white pt-18 lg:fixed lg:inset-y-0 lg:left-0 lg:flex lg:w-112 lg:items-start lg:overflow-y-auto xl:w-120">
        {bookisbn && <ShowLoadedBookIsbnInfo bookisbn={bookisbn}></ShowLoadedBookIsbnInfo>}
        {!bookisbn && <ShowDefaultImageToggler />}
      </div>
      <main className="border-t border-slate-200 bg-white lg:relative lg:mb-28 lg:ml-112 lg:border-t-0 xl:ml-120">
        <div className="relative">
          <div className="bg-white pt-16 pb-12 sm:pb-4 lg:pt-12">
            <Container>
              <h1 className="text-2xl font-bold leading-7 text-gray-700 lg:pt-20">Recenzje</h1>
            </Container>
            <div className="divide-y divide-slate-100 sm:mt-4 lg:mt-8 lg:border-t lg:border-slate-100">
              {episodes.map((episode) => (
                <EpisodeEntry key={episode.uid} episode={episode} onClick={handleReviewPlay} />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export async function getStaticProps() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/tracks`);

  const json = await res.json();
  const reviews = json.results.slice(0, 5);

  return {
    props: {
      episodes: reviews.map(({ id, title, content, file, created_at, uid, bookisbn }, index) => ({
        id,
        title: `${index + 1}. ${title}`,
        created_at,
        content,
        audio: {
          src: file,
          type: 'audio/mpeg',
        },
        date: created_at,
        uid,
        bookisbn,
      })),
    },
    revalidate: 10,
  };
}

Home.layout = 'Audio';
