import AboutSection from '@/components/common/AboutSection';
import bookImg from '@/images/bookCover.webp';
import bookPile from '@/images/bookPile.webp';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

export default function ShowDefaultImageToggler() {
  const [isReaderSelected, setReaderSelected] = useState(true);

  return (
    <div className="relative z-10 mx-auto mt-10 px-4 pb-4 pt-10 sm:px-6 md:max-w-2xl md:px-4 lg:min-h-full lg:flex-auto lg:border-x lg:border-slate-200 lg:py-12 lg:px-8 xl:px-12">
      {isReaderSelected ? (
        <Link
          href="/"
          className="relative mx-auto block w-48 overflow-hidden rounded-lg bg-slate-200 shadow-xl shadow-slate-200 sm:w-64 sm:rounded-xl lg:w-auto lg:rounded-2xl"
          aria-label="Homepage"
          shallow
        >
          <Image
            className="w-full"
            src={bookPile}
            alt=""
            sizes="(min-width: 1024px) 20rem, (min-width: 640px) 16rem, 12rem"
            priority
            fill={false}
          />
          <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-black/10 sm:rounded-xl lg:rounded-2xl" />
        </Link>
      ) : (
        <Link
          href="/"
          className="relative mx-auto block w-48 overflow-hidden rounded-lg bg-slate-200 shadow-xl shadow-slate-200 sm:w-64 sm:rounded-xl lg:w-auto lg:rounded-2xl"
          aria-label="Homepage"
          shallow
        >
          <Image
            className="w-full blur-none transition duration-1000 ease-in-out"
            src={bookImg}
            alt=""
            sizes="(min-width: 1024px) 20rem, (min-width: 640px) 16rem, 12rem"
            priority
            fill={false}
          />
          <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-black/10 sm:rounded-xl lg:rounded-2xl" />
        </Link>
      )}
      <div className="mt-10 text-center lg:mt-12 lg:text-left">
        <p className="text-xl font-bold text-slate-900">
          Drogi{' '}
          <button
            onClick={() => setReaderSelected(true)}
            type="button"
            className={
              isReaderSelected
                ? 'rounded-full bg-gradient-to-r from-blue-400 to-yellow-500 px-2 text-white hover:from-blue-500 hover:to-yellow-400  hover:underline'
                : 'px-2 hover:underline'
            }
          >
            Czytelniku
          </button>
          {'  /  '}
          <button
            onClick={() => setReaderSelected(false)}
            type="button"
            className={
              !isReaderSelected
                ? 'rounded-full bg-gradient-to-r from-teal-400 to-yellow-500 px-2 text-white hover:from-teal-500 hover:to-yellow-400  hover:underline'
                : 'px-2 hover:underline'
            }
          >
            Wydawco
          </button>
        </p>
        <p className="mt-3 text-lg font-medium leading-8 text-slate-700">
          {isReaderSelected
            ? 'Kataloguj swoje książki i rozmawiaj o nich z innymi! Zostań częścią książkowego podziemia.'
            : 'Badaj zainteresowanie wydanymi książkami. Zaoferuj czytelnikom kontakt z autorami. Wydawaj unikatowe egzemplarze swoich książek.'}
        </p>
      </div>
      <AboutSection className="mt-12 hidden lg:block" isOpen={isReaderSelected} />
    </div>
  );
}
